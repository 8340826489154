import RailsAjax from "../shared/rails-ajax";

let question = document.querySelector(".multiple-choice-question");
let answers = Array.from(document.querySelectorAll(".multiple-choice-answer"));
let responseAlert = document.getElementById("multiple-choice-alert");

answers.forEach(function (answer) {
  let marker = answer.getElementsByClassName("answer-marker")[0];

  function handleResponse() {
    if (
      answer.dataset.correct == "true" &&
      answer.classList.contains("mc-correct")
    ) {
      answer.classList.remove("mc-correct");
      marker.querySelector(".fa-check").classList.add("hidden");
      responseAlert.innerHTML = "";

      return;
    } else if (answer.classList.contains("mc-incorrect")) {
      answer.classList.remove("mc-incorrect");
      marker.querySelector(".fa-times").classList.add("hidden");
      responseAlert.innerHTML = "";

      return;
    }

    let xhr = RailsAjax.post(question.dataset.apiUrl, {
      participant_events_multiple_choice_response: {
        content_multiple_choice_answer_id: answer.dataset.id
      }
    });

    xhr.addEventListener("load", function (event) {
      if (answer.dataset.correct == "true") {
        answer.classList.add("mc-correct");
        marker.querySelector(".fa-check").classList.remove("hidden");
        responseAlert.innerHTML =
          '<div class="alert-correct multiple-choice-alert" role="alert">' +
          answer.dataset.explanation +
          "</div>";
      } else {
        answer.classList.add("mc-incorrect");
        marker.querySelector(".fa-times").classList.remove("hidden");
        responseAlert.innerHTML =
          '<div class="alert-incorrect multiple-choice-alert" role="alert">' +
          answer.dataset.explanation +
          "</div>";
      }
    });
  }

  answer.addEventListener("click", handleResponse, false);
});
