import tinymce from "tinymce";

tinymce.init({
  selector: ".participants-rich-text",
  toolbar: [
    { name: "history", items: ["undo", "redo"] },
    { name: "styles", items: ["styles"] },
    { name: "formatting", items: ["bold", "italic", "forecolor", "backcolor"] },
    {
      name: "alignment",
      items: ["alignleft", "aligncenter", "alignright", "alignjustify"]
    },
    { name: "indentation", items: ["outdent", "indent"] }
  ],
  paste_data_images: false,
  paste_block_drop: true,
  base_url: "/tinymce",
  setup: function (editor) {
    editor.on("change", function () {
      editor.save(); // Ensure textarea value is updated
    });
  }
});
